import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";

// const link = "http://localhost:3080";
const link = "https://learning.delta-support.ro";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [userExists, setUserExists] = useState(true); // State to track if user exists
  const navigate = useNavigate();

  const onButtonClick = () => {
    // Set initial error values to empty
    setEmailError("");
    setPasswordError("");

    // Check if the user has entered both fields correctly
    if ("" === email) {
      setEmailError("Please enter your email");
      return;
    }

    if (!/^[\w.-]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
      setEmailError("Please enter a valid email");
      return;
    }

    if ("" === password) {
      setPasswordError("Please enter a password");
      return;
    }

    if (password.length < 7) {
      setPasswordError("The password must be 7 characters or longer");
      return;
    }

    // Check if email has an account associated with it
    checkAccountExists();
  };

  // Call the server API to check if the given email ID already exists
  const checkAccountExists = () => {
    fetch(`${link}/api/check-account`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((r) => r.json())
      .then((r) => {
        setUserExists(r.userExists); // Update userExists state
        if (r.userExists) {
          // If user exists, try to log in
          logIn();
        }
      });
  };

  // Log in a user using email and password
  const logIn = () => {
    fetch(`${link}/api/auth`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    })
      .then((r) => r.json())
      .then((r) => {
        if ("success" === r.message) {
          localStorage.setItem(
            "user",
            JSON.stringify({ email, token: r.token })
          );
          props.setLoggedIn(true);
          props.setEmail(email);
          navigate("/");
        } else {
          setUserExists(false);
        }
      });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onButtonClick();
    }
  };

  return (
    <div className="center-page">
      <form className={"mainContainer"} onKeyDown={handleKeyDown}>
        <div className={"titleContainer"}>
          <div className="login-text">Login</div>
        </div>
        <br />
        <div className={"inputContainer"}>
          <input
            value={email}
            placeholder="Enter your email here"
            onChange={(ev) => setEmail(ev.target.value)}
            className={"inputBox"}
            autoComplete="email"
          />
          <label className="errorLabel">{emailError}</label>
        </div>
        <br />
        <div className={"inputContainer"}>
          <input
            type="password"
            value={password}
            placeholder="Enter your password here"
            onChange={(ev) => setPassword(ev.target.value)}
            className={"inputBox"}
            autoComplete="current-password"
          />
          <label className="errorLabel">{passwordError}</label>
        </div>
        {userExists ? null : (
          <div className="errorLabel">This user does not exist</div>
        )}

        <input
          type="text"
          value={email}
          style={{ display: "none" }}
          readOnly
          tabIndex={-1}
          autoComplete="username"
        />
        <br />
        <div className={"inputContainer"}>
          <input
            className="button"
            type="button"
            onClick={onButtonClick}
            value={"LOG IN"}
          />
        </div>
      </form>
    </div>
  );
};

export default Login;
