import React, { useState, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import MainComponent from "./MainComponent";
import Navbar from "./Navbar";
import Logo from "./Logo";
import UserBox from "./UserBox";
import "./Home.css";

// const link = "http://localhost:3080";
const link = "https://learning.delta-support.ro";

const Home = (props) => {
  const { loggedIn, email: initialEmail, setLoggedIn } = props; // Rename initialEmail to avoid conflicts
  const [email, setEmail] = useState(initialEmail);
  const [firstName, setFirstName] = useState("");

  const { onLogout } = props; // Destructuring inside the function body
  const navigate = useNavigate();
  useEffect(() => {
    // Fetch the user's data from localStorage if email is not set
    if (!email) {
      const user = JSON.parse(localStorage.getItem("user"));
      if (user && user.email) {
        setEmail(user.email);
      }
    }
  }, [email]);

  useEffect(() => {
    // Fetch the first name from the database when email changes
    if (email) {
      fetch(`${link}/api/get-firstname`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((data) => {
          setFirstName(data.firstName);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  }, [email]);

  const onButtonClick = () => {
    if (loggedIn) {
      // Clear user data from local storage
      localStorage.removeItem("user");
      // Reset first name to empty string
      setFirstName("");
      // Update loggedIn state
      props.setLoggedIn(false);
    } else {
      navigate("/login");
    }
  };
  const [importCategories, setImportCategories] = useState([]);
  const initialState = {
    status: localStorage.getItem("status") || "startscreen",
    categories: importCategories,
  };

  function reducer(state, action) {
    switch (action.type) {
      case "startscreen":
        return {
          ...state,
          status: "startscreen",
        };
      case "selectCategory":
        return {
          ...state,
          status: action.category,
        };
      case "addCategory":
        return {
          ...state,
          categories: [...state.categories, action.category],
        };
      default:
        throw new Error("action unknown");
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <div className="main-view">
      <div className="test">
        {loggedIn ? (
          <>
            <Navbar>
              <Logo />
              <UserBox
                loggedIn={loggedIn}
                onLogout={onLogout}
                firstName={firstName}
                dispatch={dispatch}
              />
            </Navbar>

            <MainComponent
              loggedIn={loggedIn}
              setLoggedIn={setLoggedIn}
              email={email}
              firstName={firstName}
              state={state}
              dispatch={dispatch}
              importCategories={importCategories}
              setImportCategories={setImportCategories}
            />
          </>
        ) : (
          <div className="wrapper">
            <div className="css-typing">
              <p>This is Delta Support learning App 📖 </p>
            </div>
          </div>
        )}
      </div>
      {!loggedIn && ( // Render logout button only if not logged in
        <div className={"buttonContainer"}>
          <input
            className="button"
            type="button"
            onClick={onButtonClick}
            value={loggedIn ? "Log out" : "LOG IN PAGE"}
          />
        </div>
      )}
    </div>
  );
};

export default Home;
