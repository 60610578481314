import React, { useState, useEffect } from "react";
import axios from "axios";
import "./styles.css";

const link = "https://learning.delta-support.ro";

export default function MainComponent({
  email,
  state,
  dispatch,
  importCategories,
  setImportCategories,
}) {
  const [curOpen, setCurOpen] = useState(null);

  useEffect(() => {
    fetchDataAndUpdateState(
      `${link}/api/get-categories`,
      setImportCategories,
      "importCategories",
      email
    );
  }, [email, setImportCategories]);

  const [newCategoryName, setNewCategoryName] = useState("");

  useEffect(() => {
    localStorage.setItem("status", state.status);
  }, [state.status]);

  useEffect(() => {
    // Update categories in the initial state when importCategories changes
    importCategories.forEach((category) => {
      dispatch({ type: "addCategory", category });
    });
  }, [importCategories, dispatch]);

  const { status, categories } = state;

  async function fetchDataAndUpdateState(url, setState, propertyName, email) {
    try {
      if (email) {
        const response = await axios.post(url, { email });
        setState(response.data[propertyName]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  //////////////MARKED SPECIAL ////////////////////
  const [markedItems, setMarkedItems] = useState([]);
  // console.log(markedItems);

  useEffect(() => {
    fetchDataAndUpdateState(
      `${link}/api/get-marked-items`,
      setMarkedItems,
      "markedItems",
      email
    );
  }, [email]);

  function handleShuffle() {
    // Create a copy of the items array
    const shuffledItems = [...shuffledArray];

    // Shuffle the copied array using the Fisher-Yates (Knuth) Shuffle algorithm
    for (let i = shuffledItems.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledItems[i], shuffledItems[j]] = [
        shuffledItems[j],
        shuffledItems[i],
      ];
    }

    // Set the state with the shuffled array
    setShuffledArray(shuffledItems);
  }

  ///////////////////////////////////////////////////////////////////////////////////////TEST 2
  const [itemsAnsible, setItemsAnsible] = useState([]);
  const [itemsCpanel, setItemsCpanel] = useState([]);
  const [itemsNetwork, setItemsNetwork] = useState([]);
  const [itemsPython, setItemsPython] = useState([]);
  // Linux Categories
  const [itemsLinuxGeneral, setItemsLinuxGeneral] = useState([]);
  const [itemsLinuxNetwork, setItemsLinuxNetwork] = useState([]);
  const [itemsLinuxPackageManager, setItemsLinuxPackageManager] = useState([]);
  const [itemsLinuxSelinux, setItemsLinuxSelinux] = useState([]);
  const [itemsLinuxStorageManagement, setItemsLinuxStorageManagement] =
    useState([]);
  const [itemsLinuxUserManagement, setItemsLinuxUserManagement] = useState([]);
  // Services Categories
  const [itemsDNS, setItemsDNS] = useState([]);
  const [itemsHA_CLUSTER, setItemsHA_CLUSTER] = useState([]);
  const [itemsApache, setItemsApache] = useState([]);
  const [itemsNginx, setItemsNginx] = useState([]);
  const [itemsMysql, setItemsMysql] = useState([]);
  const [itemsNTP, setItemsNTP] = useState([]);
  const [itemsOpenLDAP, setItemsOpenLDAP] = useState([]);
  const [itemsVirtualization, setItemsVirtualization] = useState([]);
  const [itemsContainers, setItemsContainers] = useState([]);
  const [itemsIdentityManagement, setItemsIdentityManagement] = useState([]);
  const [itemsKerberos, setItemsKerberos] = useState([]);
  const [itemsOpenVPN, setItemsOpenVPN] = useState([]);
  // Shuffled Marked
  const [shuffledArray, setShuffledArray] = useState([]);

  const fetchDataAndUpdateState2 = async (
    url,
    setState,
    propertyName,
    email
  ) => {
    try {
      if (email) {
        const response = await axios.post(url, { email });
        setState(response.data[propertyName]);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Fetch each category 1 by 1
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsAnsible`,
      setItemsAnsible,
      "itemsAnsible",
      email
    );
  }, [email]);

  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsCpanel`,
      setItemsCpanel,
      "itemsCpanel",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsNetwork`,
      setItemsNetwork,
      "itemsNetwork",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsPython`,
      setItemsPython,
      "itemsPython",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsLinuxGeneral`,
      setItemsLinuxGeneral,
      "itemsLinuxGeneral",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsLinuxNetwork`,
      setItemsLinuxNetwork,
      "itemsLinuxNetwork",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsLinuxSelinux`,
      setItemsLinuxSelinux,
      "itemsLinuxSelinux",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsLinuxPackageManager`,
      setItemsLinuxPackageManager,
      "itemsLinuxPackageManager",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsLinuxStorageManagement`,
      setItemsLinuxStorageManagement,
      "itemsLinuxStorageManagement",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsLinuxUserManagement`,
      setItemsLinuxUserManagement,
      "itemsLinuxUserManagement",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsDNS`,
      setItemsDNS,
      "itemsDNS",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsHA_CLUSTER`,
      setItemsHA_CLUSTER,
      "itemsHA_CLUSTER",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsApache`,
      setItemsApache,
      "itemsApache",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsNginx`,
      setItemsNginx,
      "itemsNginx",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsMysql`,
      setItemsMysql,
      "itemsMysql",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsNTP`,
      setItemsNTP,
      "itemsNTP",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsOpenLDAP`,
      setItemsOpenLDAP,
      "itemsOpenLDAP",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsVirtualization`,
      setItemsVirtualization,
      "itemsVirtualization",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsContainers`,
      setItemsContainers,
      "itemsContainers",
      email
    );
  }, [email]);
  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsIdentityManagement`,
      setItemsIdentityManagement,
      "itemsIdentityManagement",
      email
    );
  }, [email]);

  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsKerberos`,
      setItemsKerberos,
      "itemsKerberos",
      email
    );
  }, [email]);

  useEffect(() => {
    fetchDataAndUpdateState2(
      `${link}/api/get-itemsOpenVPN`,
      setItemsOpenVPN,
      "itemsOpenVPN",
      email
    );
  }, [email]);

  useEffect(() => {
    // Combine arrays
    const combinedArray = [
      ...itemsAnsible,
      ...itemsCpanel,
      ...itemsNetwork,
      ...itemsPython,
      ...itemsLinuxGeneral,
      ...itemsLinuxSelinux,
      ...itemsLinuxNetwork,
      ...itemsLinuxPackageManager,
      ...itemsLinuxStorageManagement,
      ...itemsLinuxUserManagement,
      // services
      ...itemsDNS,
      ...itemsHA_CLUSTER,
      ...itemsApache,
      ...itemsNginx,
      ...itemsMysql,
      ...itemsNTP,
      ...itemsOpenLDAP,
      ...itemsVirtualization,
      ...itemsContainers,
      ...itemsIdentityManagement,
      ...itemsKerberos,
      ...itemsOpenVPN,
    ];

    // Filter out items with marked set to false
    const shuffledAndMarkedArray = combinedArray.filter((item) => item.marked);

    // Set the shuffled and marked array in state
    setShuffledArray(shuffledAndMarkedArray);
  }, [
    itemsAnsible,
    itemsCpanel,
    itemsNetwork,
    itemsPython,
    itemsLinuxGeneral,
    itemsLinuxNetwork,
    itemsLinuxSelinux,
    itemsLinuxPackageManager,
    itemsLinuxStorageManagement,
    itemsLinuxUserManagement,
    //services
    itemsDNS,
    itemsHA_CLUSTER,
    itemsApache,
    itemsNginx,
    itemsMysql,
    itemsNTP,
    itemsOpenLDAP,
    itemsVirtualization,
    itemsContainers,
    itemsIdentityManagement,
    itemsKerberos,
    itemsOpenVPN,
  ]);

  function handleToggleItemMarked(id) {
    const itemIndex = shuffledArray.findIndex((item) => item.id === id);

    const categoryArray = getCategoryArray(id);
    const categoryItemIndex = categoryArray.findIndex((item) => item.id === id);
    if (categoryItemIndex !== -1) {
      updateCategoryItem(categoryArray, categoryItemIndex, itemIndex === -1);
    }
  }
  function getCategoryArray(id) {
    if (itemsAnsible.some((item) => item.id === id)) {
      return itemsAnsible;
    } else if (itemsCpanel.some((item) => item.id === id)) {
      return itemsCpanel;
    } else if (itemsNetwork.some((item) => item.id === id)) {
      return itemsNetwork;
    } else if (itemsPython.some((item) => item.id === id)) {
      return itemsPython;
    } else if (itemsLinuxGeneral.some((item) => item.id === id)) {
      return itemsLinuxGeneral;
    } else if (itemsLinuxNetwork.some((item) => item.id === id)) {
      return itemsLinuxNetwork;
    } else if (itemsLinuxPackageManager.some((item) => item.id === id)) {
      return itemsLinuxPackageManager;
    } else if (itemsLinuxSelinux.some((item) => item.id === id)) {
      return itemsLinuxSelinux;
    } else if (itemsLinuxStorageManagement.some((item) => item.id === id)) {
      return itemsLinuxStorageManagement;
    } else if (itemsLinuxUserManagement.some((item) => item.id === id)) {
      return itemsLinuxUserManagement;
    } else if (itemsDNS.some((item) => item.id === id)) {
      return itemsDNS;
    } else if (itemsHA_CLUSTER.some((item) => item.id === id)) {
      return itemsHA_CLUSTER;
    } else if (itemsApache.some((item) => item.id === id)) {
      return itemsApache;
    } else if (itemsNginx.some((item) => item.id === id)) {
      return itemsNginx;
    } else if (itemsMysql.some((item) => item.id === id)) {
      return itemsMysql;
    } else if (itemsNTP.some((item) => item.id === id)) {
      return itemsNTP;
    } else if (itemsOpenLDAP.some((item) => item.id === id)) {
      return itemsOpenLDAP;
    } else if (itemsVirtualization.some((item) => item.id === id)) {
      return itemsVirtualization;
    } else if (itemsContainers.some((item) => item.id === id)) {
      return itemsContainers;
    } else if (itemsIdentityManagement.some((item) => item.id === id)) {
      return itemsIdentityManagement;
    } else if (itemsKerberos.some((item) => item.id === id)) {
      return itemsKerberos;
    } else if (itemsOpenVPN.some((item) => item.id === id)) {
      return itemsOpenVPN;
    }

    return [];
  }

  /// Get Category array Name
  // const categoryArrayName = getCategoryArrayName(id);

  function getCategoryArrayName(id) {
    if (itemsAnsible.some((item) => item.id === id)) {
      return "Ansible";
    } else if (itemsCpanel.some((item) => item.id === id)) {
      return "Cpanel";
    } else if (itemsNetwork.some((item) => item.id === id)) {
      return "Network";
    } else if (itemsPython.some((item) => item.id === id)) {
      return "Python";
    } else if (itemsLinuxGeneral.some((item) => item.id === id)) {
      return "Linux >>> General";
    } else if (itemsLinuxNetwork.some((item) => item.id === id)) {
      return "Linux >>> Network";
    } else if (itemsLinuxPackageManager.some((item) => item.id === id)) {
      return "Linux >>> PackageManager";
    } else if (itemsLinuxSelinux.some((item) => item.id === id)) {
      return "Linux >>> Selinux";
    } else if (itemsLinuxStorageManagement.some((item) => item.id === id)) {
      return "Linux >>> StorageManagement";
    } else if (itemsLinuxUserManagement.some((item) => item.id === id)) {
      return "Linux >>> UserManagement";
    } else if (itemsDNS.some((item) => item.id === id)) {
      return "Linux >>> Services >>> DNS";
    } else if (itemsHA_CLUSTER.some((item) => item.id === id)) {
      return "Linux >>> Services >>> HA_CLUSTER";
    } else if (itemsApache.some((item) => item.id === id)) {
      return "Linux >>> Services >>> Apache";
    } else if (itemsNginx.some((item) => item.id === id)) {
      return "Linux >>> Services >>> Nginx";
    } else if (itemsMysql.some((item) => item.id === id)) {
      return "Linux >>> Services >>> Mysql";
    } else if (itemsNTP.some((item) => item.id === id)) {
      return "Linux >>> Services >>> NTP";
    } else if (itemsOpenLDAP.some((item) => item.id === id)) {
      return "Linux >>> Services >>> OpenLDAP";
    } else if (itemsVirtualization.some((item) => item.id === id)) {
      return "Linux >>> Services >>> Virtualization";
    } else if (itemsContainers.some((item) => item.id === id)) {
      return "Linux >>> Services >>> Containers";
    } else if (itemsIdentityManagement.some((item) => item.id === id)) {
      return "Linux >>> Services >>> IdentityManagement";
    } else if (itemsKerberos.some((item) => item.id === id)) {
      return "Linux >>> Services >>> Kerberos";
    } else if (itemsOpenVPN.some((item) => item.id === id)) {
      return "Linux >>> Services >>> OpenVPN";
    }

    return [];
  }
  //////

  function updateCategoryItem(categoryArray, index, marked) {
    const updatedCategoryArray = [...categoryArray];
    updatedCategoryArray[index] = {
      ...categoryArray[index],
      marked: marked,
    };
    updateCategoryState(categoryArray, updatedCategoryArray);
  }

  function updateCategoryState(originalArray, updatedArray) {
    if (originalArray === itemsAnsible) {
      setItemsAnsible(updatedArray);
    } else if (originalArray === itemsCpanel) {
      setItemsCpanel(updatedArray);
    } else if (originalArray === itemsNetwork) {
      setItemsNetwork(updatedArray);
    } else if (originalArray === itemsPython) {
      setItemsPython(updatedArray);
    } else if (originalArray === itemsLinuxGeneral) {
      setItemsLinuxGeneral(updatedArray);
    } else if (originalArray === itemsLinuxNetwork) {
      setItemsLinuxNetwork(updatedArray);
    } else if (originalArray === itemsLinuxPackageManager) {
      setItemsLinuxPackageManager(updatedArray);
    } else if (originalArray === itemsLinuxSelinux) {
      setItemsLinuxSelinux(updatedArray);
    } else if (originalArray === itemsLinuxStorageManagement) {
      setItemsLinuxStorageManagement(updatedArray);
    } else if (originalArray === itemsLinuxUserManagement) {
      setItemsLinuxUserManagement(updatedArray);
    } else if (originalArray === itemsDNS) {
      setItemsDNS(updatedArray);
    } else if (originalArray === itemsHA_CLUSTER) {
      setItemsHA_CLUSTER(updatedArray);
    } else if (originalArray === itemsApache) {
      setItemsApache(updatedArray);
    } else if (originalArray === itemsNginx) {
      setItemsNginx(updatedArray);
    } else if (originalArray === itemsMysql) {
      setItemsMysql(updatedArray);
    } else if (originalArray === itemsNTP) {
      setItemsNTP(updatedArray);
    } else if (originalArray === itemsOpenLDAP) {
      setItemsOpenLDAP(updatedArray);
    } else if (originalArray === itemsVirtualization) {
      setItemsVirtualization(updatedArray);
    } else if (originalArray === itemsContainers) {
      setItemsContainers(updatedArray);
    } else if (originalArray === itemsIdentityManagement) {
      setItemsIdentityManagement(updatedArray);
    } else if (originalArray === itemsKerberos) {
      setItemsKerberos(updatedArray);
    } else if (originalArray === itemsOpenVPN) {
      setItemsOpenVPN(updatedArray);
    }
  }

  function handleUpdateStateMarked() {
    handleUpdateStateAnsible();
    handleUpdateStateCpanel();
    handleUpdateStateNetwork();
    handleUpdateStatePython();
    handleUpdateStateLinuxGeneral();
    handleUpdateStateLinuxSelinux();
    handleUpdateStateLinuxNetwork();
    handleUpdateStateLinuxPackageManager();
    handleUpdateStateLinuxStorageManagement();
    handleUpdateStateLinuxUserManagement();
    handleUpdateStateDNS();
    handleUpdateStateHA_CLUSTER();
    handleUpdateStateApache();
    handleUpdateStateNginx();
    handleUpdateStateMysql();
    handleUpdateStateNTP();
    handleUpdateStateOpenLDAP();
    handleUpdateStateVirtualization();
    handleUpdateStateContainers();
    handleUpdateStateIdentityManagement();
    handleUpdateStateKerberos();
    handleUpdateStateOpenVPN();
  }

  // Update Items in Backend. Sync Items States with Backend on each interaction
  const handleUpdateStateAnsible = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsAnsible`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsAnsible }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateCpanel = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsCpanel`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsCpanel }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateNetwork = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsNetwork`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsNetwork }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStatePython = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsPython`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsPython }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateLinuxGeneral = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsLinuxGeneral`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsLinuxGeneral }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateLinuxSelinux = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsLinuxSelinux`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsLinuxSelinux }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateLinuxNetwork = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsLinuxNetwork`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsLinuxNetwork }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateLinuxPackageManager = async () => {
    try {
      const response = await fetch(
        `${link}/api/update2-itemsLinuxPackageManager`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, itemsLinuxPackageManager }),
        }
      );

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateLinuxStorageManagement = async () => {
    try {
      const response = await fetch(
        `${link}/api/update2-itemsLinuxStorageManagement`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, itemsLinuxStorageManagement }),
        }
      );

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateLinuxUserManagement = async () => {
    try {
      const response = await fetch(
        `${link}/api/update2-itemsLinuxUserManagement`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, itemsLinuxUserManagement }),
        }
      );

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //services
  const handleUpdateStateDNS = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsDNS`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsDNS }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateHA_CLUSTER = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsHA_CLUSTER`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsHA_CLUSTER }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateApache = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsApache`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsApache }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateNginx = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsNginx`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsNginx }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateMysql = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsMysql`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsMysql }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateNTP = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsNTP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsNTP }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateOpenLDAP = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsOpenLDAP`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsOpenLDAP }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateVirtualization = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsVirtualization`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsVirtualization }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateContainers = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsContainers`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsContainers }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleUpdateStateIdentityManagement = async () => {
    try {
      const response = await fetch(
        `${link}/api/update2-itemsIdentityManagement`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, itemsIdentityManagement }),
        }
      );

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateKerberos = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsKerberos`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsKerberos }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleUpdateStateOpenVPN = async () => {
    try {
      const response = await fetch(`${link}/api/update2-itemsOpenVPN`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, itemsOpenVPN }),
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  function handleClearMarkedItems() {
    const sortedItems1 = [...itemsAnsible];
    const sortedItems2 = [...itemsCpanel];
    const sortedItems3 = [...itemsNetwork];
    const sortedItems4 = [...itemsPython];
    const sortedItems5 = [...itemsLinuxGeneral];
    const sortedItems6 = [...itemsLinuxNetwork];
    const sortedItems7 = [...itemsLinuxPackageManager];
    const sortedItems8 = [...itemsLinuxSelinux];
    const sortedItems9 = [...itemsLinuxStorageManagement];
    const sortedItems10 = [...itemsLinuxUserManagement];
    // services
    const sortedItems11 = [...itemsDNS];
    const sortedItems12 = [...itemsHA_CLUSTER];
    const sortedItems13 = [...itemsApache];
    const sortedItems14 = [...itemsNginx];
    const sortedItems15 = [...itemsMysql];
    const sortedItems16 = [...itemsNTP];
    const sortedItems17 = [...itemsOpenLDAP];
    const sortedItems18 = [...itemsVirtualization];
    const sortedItems19 = [...itemsContainers];
    const sortedItems20 = [...itemsIdentityManagement];
    const sortedItems21 = [...itemsKerberos];
    const sortedItems22 = [...itemsOpenVPN];

    const allSortedItems = [
      sortedItems1,
      sortedItems2,
      sortedItems3,
      sortedItems4,
      sortedItems5,
      sortedItems6,
      sortedItems7,
      sortedItems8,
      sortedItems9,
      sortedItems10,
      sortedItems11,
      sortedItems12,
      sortedItems13,
      sortedItems14,
      sortedItems15,
      sortedItems16,
      sortedItems17,
      sortedItems18,
      sortedItems19,
      sortedItems20,
      sortedItems21,
      sortedItems22,
    ];

    allSortedItems.forEach((sortedArray) => {
      sortedArray.forEach((obj) => {
        obj.marked = false;
        obj.note = "";
      });
    });

    // Set the state with the shuffled array
    setItemsAnsible(sortedItems1);
    setItemsCpanel(sortedItems2);
    setItemsNetwork(sortedItems3);
    setItemsPython(sortedItems4);
    setItemsLinuxGeneral(sortedItems5);
    setItemsLinuxNetwork(sortedItems6);
    setItemsLinuxPackageManager(sortedItems7);
    setItemsLinuxSelinux(sortedItems8);
    setItemsLinuxStorageManagement(sortedItems9);
    setItemsLinuxUserManagement(sortedItems10);
    setItemsDNS(sortedItems11);
    setItemsHA_CLUSTER(sortedItems12);
    setItemsApache(sortedItems13);
    setItemsNginx(sortedItems14);
    setItemsMysql(sortedItems15);
    setItemsNTP(sortedItems16);
    setItemsOpenLDAP(sortedItems17);
    setItemsVirtualization(sortedItems18);
    setItemsContainers(sortedItems19);
    setItemsIdentityManagement(sortedItems20);
    setItemsIdentityManagement(sortedItems21);
    setItemsIdentityManagement(sortedItems22);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <div>
      {status === "startscreen" && (
        <StartScreen
          dispatch={dispatch}
          categories={categories}
          newCategoryName={newCategoryName}
          setNewCategoryName={setNewCategoryName}
          email={email}
        />
      )}
      {categories.map(
        (category) =>
          category !== "Marked" &&
          status === category && (
            <CategoryPage
              key={category}
              category={category}
              dispatch={dispatch}
              fetchDataAndUpdateState={fetchDataAndUpdateState}
              email={email}
            />
          )
      )}
      {status === "Marked" && (
        <>
          <div className="back-to-home-btn">
            <Button onClick={() => dispatch({ type: "startscreen" })}>
              <img src="poze/home.svg" alt="homesvg" className="btn-svg" />
              BACK TO HOME
            </Button>
          </div>
          <div className="header-main">
            <img
              src="logos/Markedlogo.png"
              alt="markedlogo"
              className="statslogo"
            />

            <div className="category-text">
              <span className="category-name">Marked</span>
            </div>
          </div>
          <TopMainButtonsMarked
            onShuffleItems={handleShuffle}
            onUpdateState={handleUpdateStateMarked}
            onClearMarkedItems={handleClearMarkedItems}
          />
          <AccordionMarked
            items={shuffledArray}
            setItems={setShuffledArray}
            curOpen={curOpen}
            setCurOpen={setCurOpen}
            getCategoryArrayName={getCategoryArrayName}
            onToggleItem={handleToggleItemMarked}
          />
        </>
      )}
    </div>
  );
}

function StartScreen({
  dispatch,
  categories,
  newCategoryName,
  setNewCategoryName,
  email,
}) {
  const handleInputChange = (e) => {
    setNewCategoryName(e.target.value);
  };

  const handleAddCategory = async () => {
    if (newCategoryName.trim() !== "") {
      dispatch({ type: "addCategory", category: newCategoryName });
      await createNewItemBackend(newCategoryName); // Pass newCategoryName to createNewItemBackend
      setNewCategoryName("");
    }
  };

  const createNewItemBackend = async (categoryName) => {
    // Modify createNewItemBackend to accept categoryName
    try {
      const response = await fetch(`${link}/api/create-newItem`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, categoryName }), // Send categoryName in the request body
      });

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="main-startscreen">
      <h1 className="main-welcometext">
        "Expand your knowledge, one category at a time. Explore, learn, and grow
        with our intuitive learning app."
      </h1>
      <div className="main-top">
        <img src="poze/learn1.svg" alt="image1" className="home-img-left" />
        <div className="new-category-btn-input">
          <div className="new-category-text">Create new category</div>
          <input
            type="text"
            value={newCategoryName}
            onChange={handleInputChange}
            placeholder="Write new category name"
            className="new-category-input"
          />

          <Button onClick={handleAddCategory}>
            <img src="poze/add.svg" alt="addsvg" className="btn-svg" />
            CREATE
          </Button>
        </div>
        <img src="poze/learn2.svg" alt="image1" className="home-img-left" />
      </div>
      <div className="btn-main-menu">
        {categories.map((category) =>
          category !== "Marked" ? (
            <ButtonMenu
              key={category}
              onClick={() => dispatch({ type: "selectCategory", category })}
            >
              {category}
            </ButtonMenu>
          ) : (
            <ButtonMenuMarked
              key={category}
              onClick={() => dispatch({ type: "selectCategory", category })}
            >
              {category}
            </ButtonMenuMarked>
          )
        )}
      </div>

      <Footer />
    </div>
  );
}

function CategoryPage({ category, dispatch, fetchDataAndUpdateState, email }) {
  const [items, setItems] = useState([]);
  const [curOpen, setCurOpen] = useState(null);

  useEffect(() => {
    fetchDataAndUpdateState(
      `${link}/api/get-items${category.charAt(0).toUpperCase()}${category.slice(
        1
      )}`,
      setItems,
      `items${category.charAt(0).toUpperCase()}${category.slice(1)}`,
      email
    );
  }, [email, fetchDataAndUpdateState, category]);

  function handleToggleItem(id) {
    setItems((items) =>
      items.map((item) =>
        item.id === id ? { ...item, marked: !item.marked } : item
      )
    );
  }
  function handleToggleItem2(id, newNote) {
    setItems((items) =>
      items.map((item) =>
        item.id === id
          ? {
              ...item,
              note: newNote !== undefined ? newNote : item.note,
            }
          : item
      )
    );
  }
  function handleDeleteItem(id) {
    setItems((items) => items.filter((item) => item.id !== id));
  }

  function handleShuffle() {
    // Create a copy of the items array
    const shuffledItems = [...items];

    // Shuffle the copied array using the Fisher-Yates (Knuth) Shuffle algorithm
    for (let i = shuffledItems.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledItems[i], shuffledItems[j]] = [
        shuffledItems[j],
        shuffledItems[i],
      ];
    }

    // Set the state with the shuffled array
    setItems(shuffledItems);
  }
  function handleSort() {
    // Create a copy of the items array
    const sortedItems = [...items];

    // Shuffle the copied array using the Fisher-Yates (Knuth) Shuffle algorithm
    sortedItems.sort((a, b) => {
      // Sort by marked property (true comes before false)
      if (a.marked && !b.marked) {
        return -1;
      } else if (!a.marked && b.marked) {
        return 1;
      } else {
        // If both have the same marked property, maintain the original order
        return 0;
      }
    });

    // Set the state with the shuffled array
    setItems(sortedItems);
  }

  function handleClearMarked() {
    // Create a copy of the items array
    const sortedItems = [...items];

    // Shuffle the copied array using the Fisher-Yates (Knuth) Shuffle algorithm
    sortedItems.forEach((obj) => {
      obj.marked = false;
      obj.note = ""; // Set marked to false for each item
    });

    // Set the state with the shuffled array
    setItems(sortedItems);
  }
  const [buttonPressedAddForm, setButtonPressedAddForm] = useState(false);
  function handleShowAddItemForm() {
    setButtonPressedAddForm((cur) => !cur);
  }

  function handleAddItem(item) {
    console.log("intrebare noua adaugata");
    setItems((items) => [...items, item]);
  }

  const handleUpdateState = async () => {
    try {
      const response = await fetch(
        `${link}/api/update-items${category
          .charAt(0)
          .toUpperCase()}${category.slice(1)}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, items }),
        }
      );

      const data = await response.json();
      console.log(data.message);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // console.log(category);

  return (
    <>
      <div className="back-to-home-btn">
        <Button onClick={() => dispatch({ type: "startscreen" })}>
          <img src="poze/home.svg" alt="homesvg" className="btn-svg" />
          BACK TO HOME
        </Button>
      </div>
      <div>
        <div className="header-main">
          <img
            src={`logos/${category}logo.png`}
            alt="statslogo"
            className="statslogo"
            onError={(e) => {
              e.target.src = "logos/nologo.png"; // Replace with the fallback image URL
            }}
          />

          <div className="category-text">
            <span className="category-name">
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </span>
          </div>
        </div>
        <TopMainButtons
          onShuffleItems={handleShuffle}
          onSortItems={handleSort}
          onClearMarkedItems={handleClearMarked}
          handleShowAddItemForm={handleShowAddItemForm}
          buttonPressedAddForm={buttonPressedAddForm}
          onUpdateState={handleUpdateState}
          onAddItem={handleAddItem}
        />
        {/* Render Accordion component */}
        <Accordion
          category={category}
          onToggleItem={handleToggleItem}
          onToggleItem2={handleToggleItem2}
          curOpen={curOpen}
          setCurOpen={setCurOpen}
          items={items}
          setItems={setItems}
          onDeleteItem={handleDeleteItem}
        />
      </div>
      <Footer />
    </>
  );
}

function Accordion({
  category,
  onToggleItem,
  onToggleItem2,
  onDeleteItem,
  curOpen,
  setCurOpen,
  setItems,
  items,
}) {
  return (
    <div className="accordion">
      {items.map((el, i) => (
        <AccordionItem
          key={el.id}
          curOpen={curOpen}
          setCurOpen={setCurOpen}
          title={el.title}
          num={i}
          item={el}
          onToggleItem={onToggleItem}
          onToggleItem2={onToggleItem2}
          onDeleteItem={onDeleteItem}
        />
      ))}
    </div>
  );
}

function AccordionItem({
  num,
  title,
  item,
  curOpen,
  setCurOpen,
  onToggleItem,
  onToggleItem2,
  onDeleteItem,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const isOpen = item.id === curOpen;

  function handleToggle() {
    setCurOpen(isOpen ? null : item.id);
    // console.log(item.id);
  }

  function handleEdit() {
    setIsEditing(!isEditing);
  }

  const calculateHeight = () => {
    const lineHeight = 16;
    const minRows = 6;

    const lines = item.text ? item.text.length : 0;
    const minHeight = minRows * lineHeight;

    return Math.max(lines * lineHeight, minHeight);
  };

  return (
    <div>
      <div
        className={`item ${isOpen ? "open" : ""}`}
        style={{ backgroundColor: item.marked ? "#5643fd" : "" }}
        onClick={handleToggle}
      >
        <p
          className="number"
          onClick={handleToggle}
          style={{
            color: item.marked ? "white" : "",
          }}
        >
          {num < 9 ? `0${num + 1}` : num + 1}
        </p>
        <p
          className="title"
          onClick={handleToggle}
          style={{
            fontSize: "20px",
            color: item.marked ? "white" : "",
          }}
        >
          {title}
        </p>
        <p
          className="icon"
          onClick={handleToggle}
          style={{
            color: item.marked ? "white" : "",
          }}
        >
          {isOpen ? "-" : "+"}
        </p>

        {isOpen && (
          <div className="content-box">
            <textarea
              wrap="off"
              className="textarea"
              style={{ height: `${calculateHeight()}px`, minHeight: "40px" }}
              value={item.text ? item.text.join("\n") : ""}
              onClick={(e) => e.stopPropagation()}
              readOnly={true}
            ></textarea>
            <div className="photo-box">
              {item.photoName == null ? null : (
                <img className="photo" src={item.photoName} alt={item.id} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="acc-buttons">
        <input
          className="check"
          type="checkbox"
          value={item.marked}
          checked={item.marked}
          onChange={() => onToggleItem(item.id)}
        />
        <button
          className="edit-button"
          onClick={handleEdit}
          style={{ backgroundColor: isEditing ? "#5643fd" : "" }}
        >
          ✏️
        </button>
        <button className="delete-button" onClick={() => onDeleteItem(item.id)}>
          ❌
        </button>
      </div>
      {isEditing && (
        <div className="note">
          <textarea
            wrap="off"
            className="textarea note"
            style={{ height: `${calculateHeight()}px`, minHeight: "40px" }}
            value={item.note}
            onChange={(event) => {
              const newNote = event.target.value;
              onToggleItem2(item.id, newNote);
            }}
          ></textarea>
        </div>
      )}
    </div>
  );
}

function TopMainButtons({
  onShuffleItems,
  onSortItems,
  onClearMarkedItems,
  handleShowAddItemForm,
  buttonPressedAddForm,
  onUpdateState,
  onAddItem,
}) {
  return (
    <div className="topmain">
      <div className="btn-container-secondary ">
        <Button className="button sort color" onClick={onUpdateState}>
          <img src="poze/update.svg" alt="updatesvg" className="btn-svg" />
          UPDATE STATE
        </Button>
        <Button onClick={handleShowAddItemForm} pressed={buttonPressedAddForm}>
          <img src="poze/add.svg" alt="addsvg" className="btn-svg" />
          {buttonPressedAddForm ? "CLOSE FORM" : "ADD QUESTION"}
        </Button>
        <Button className="button shuffle-button" onClick={onShuffleItems}>
          <img src="poze/mix.svg" alt="mixsvg" className="btn-svg" />
          SHUFFLE
        </Button>
        <button className="button sort" onClick={onSortItems}>
          <img
            src="poze/markedontop.svg"
            alt="markedontopsvg"
            className="btn-svg"
          />
          MARKED ON TOP
        </button>
        <button className="button sort" onClick={onClearMarkedItems}>
          CLEAR
          <img src="poze/marked.svg" alt="markedsvg" className="btn-svg" />
          &
          <img src="poze/pencil.svg" alt="pencilsvg" className="btn-svg" />
        </button>
      </div>
      {buttonPressedAddForm && <FormAddQuestion onAddItem={onAddItem} />}
    </div>
  );
}

function Button({ children, onClick, pressed }) {
  return (
    <button
      className="button"
      onClick={onClick}
      style={{ background: pressed ? "#5643fd" : "" }}
    >
      {children}
    </button>
  );
}

function FormAddQuestion({ onAddItem }) {
  const [nqtitle, setNqtitle] = useState("");
  const [nqtext, setNqtext] = useState("");

  function handleSubmit(e) {
    e.preventDefault();
    if (!nqtitle || !nqtext) return;

    const newItem = {
      title: nqtitle,
      text: [nqtext],
      id: crypto.randomUUID(),
      marked: false,
      key: crypto.randomUUID(),
      note: "",
    };

    onAddItem(newItem);
    setNqtitle("");
    setNqtext("");
  }

  return (
    <form className="new-question-form" onSubmit={handleSubmit}>
      <label className="new-question-text">Question</label>
      <textarea
        wrap="off"
        className="textarea note"
        placeholder="Write here the question title . . . "
        value={nqtitle}
        onChange={(e) => setNqtitle(e.target.value)}
        style={{ height: "42px" }}
      ></textarea>

      <label className="new-question-text">Answer</label>
      <textarea
        wrap="off"
        className="textarea note"
        placeholder="Write here the question answer . . . "
        value={nqtext}
        onChange={(e) => setNqtext(e.target.value)}
        style={{ height: "140px" }}
      ></textarea>

      <button className="btn-add-question"> Add question to list</button>
    </form>
  );
}

function ButtonMenu({ children, onClick, pressed }) {
  return (
    <button
      className="button-menu"
      onClick={onClick}
      style={{ background: pressed ? "#5643fd" : "" }}
    >
      {children}
    </button>
  );
}

function Footer() {
  return <div className="footer"> Made by Sideon using the 💪 and 💡 </div>;
}

function AccordionMarked({
  items,
  curOpen,
  setCurOpen,
  onToggleItem,
  onToggleItem2,
  onDeleteItem,
  updateItemsAnsible,
  getCategoryArrayName,
}) {
  return (
    <div className="accordion">
      {items.map((el, i) => (
        <AccordionItemMarked
          key={el.id}
          curOpen={curOpen}
          setCurOpen={setCurOpen}
          title={el.title}
          num={i}
          item={el}
          onToggleItem={onToggleItem}
          onToggleItem2={onToggleItem2}
          onDeleteItem={onDeleteItem}
          updateItemsAnsible={updateItemsAnsible}
          getCategoryArrayName={getCategoryArrayName}
        >
          {el.text}
        </AccordionItemMarked>
      ))}
    </div>
  );
}

function AccordionItemMarked({
  num,
  title,
  item,
  curOpen,
  setCurOpen,
  onToggleItem,
  onToggleItem2,
  getCategoryArrayName,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const isOpen = item.id === curOpen;

  function handleToggle() {
    setCurOpen(isOpen ? null : item.id);
  }

  function handleEdit() {
    setIsEditing(!isEditing);
  }

  const calculateHeight = () => {
    const lineHeight = 16;
    const minRows = 6;

    const lines = item.text ? item.text.length : 0;
    const minHeight = minRows * lineHeight;

    return Math.max(lines * lineHeight, minHeight);
  };
  function handleTextArea() {
    return;
  }

  return (
    <div>
      <div
        className={`item ${isOpen ? "open" : ""}`}
        style={{ backgroundColor: item.marked ? "#5643fd" : "" }}
        onClick={handleToggle}
      >
        <p
          className="number"
          onClick={handleToggle}
          style={{
            color: item.marked ? "white" : "",
          }}
        >
          {num < 9 ? `0${num + 1}` : num + 1}
        </p>
        <p
          className="title"
          onClick={handleToggle}
          style={{
            fontSize: "20px",
            color: item.marked ? "white" : "",
          }}
        >
          {title}
        </p>
        <p
          className="icon"
          onClick={handleToggle}
          style={{
            color: item.marked ? "white" : "",
          }}
        >
          {isOpen ? "-" : "+"}
        </p>

        {isOpen && (
          <div className="content-box">
            <textarea
              wrap="off"
              className="textarea"
              style={{ height: `${calculateHeight()}px`, minHeight: "40px" }}
              value={item.text ? item.text.join("\n") : ""}
              onClick={(e) => e.stopPropagation()}
              readOnly={true}
            ></textarea>
            <div className="photo-box">
              {item.photoName == null ? null : (
                <img className="photo" src={item.photoName} alt={item.id} />
              )}
            </div>
          </div>
        )}
      </div>
      <div className="acc-buttons">
        <input
          className="check"
          type="checkbox"
          value={item.marked}
          checked={item.marked}
          onChange={() => onToggleItem(item.id)}
        />
        <button
          className="edit-button"
          onClick={handleEdit}
          style={{ backgroundColor: isEditing ? "#5643fd" : "" }}
        >
          ✏️
        </button>
        <div className="category-array">{getCategoryArrayName(item.id)}</div>
      </div>
      {isEditing && (
        <div className="note">
          <textarea
            wrap="off"
            className="textarea note"
            style={{ height: `${calculateHeight()}px`, minHeight: "40px" }}
            value={item.note}
            onChange={handleTextArea}
          ></textarea>
        </div>
      )}
    </div>
  );
}

function TopMainButtonsMarked({
  onShuffleItems,
  onClearMarkedItems,
  onUpdateState,
}) {
  return (
    <div className="topmain">
      <div className="btn-container-secondary ">
        <Button className="button sort color" onClick={onUpdateState}>
          <img src="poze/update.svg" alt="updatesvg" className="btn-svg" />
          UPDATE STATE
        </Button>

        <Button className="button shuffle-button" onClick={onShuffleItems}>
          <img src="poze/mix.svg" alt="mixsvg" className="btn-svg" />
          SHUFFLE
        </Button>

        <button className="button sort" onClick={onClearMarkedItems}>
          CLEAR
          <img src="poze/marked.svg" alt="markedsvg" className="btn-svg" />
          &
          <img src="poze/pencil.svg" alt="pencilsvg" className="btn-svg" />
        </button>
      </div>
    </div>
  );
}

function ButtonMenuMarked({ children, onClick, pressed }) {
  return (
    <button
      className="button-menu-marked"
      onClick={onClick}
      style={{ background: pressed ? "#5643fd" : "" }}
    >
      {children}
    </button>
  );
}
