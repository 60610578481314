import React, { useState, useEffect } from "react";
import axios from "axios";

// const link = "http://localhost:3080";
const link = "https://learning.delta-support.ro";

export default function UserBox({ loggedIn, onLogout, firstName, dispatch }) {
  const [allItemsLength, setAllItemsLength] = useState([]);
  const [allItemsMarkedLength, setAllItemsMarkedLength] = useState([]);

  const fetchAllItems = async () => {
    try {
      const response = await axios.post(`${link}/api/get-all-items-length`);
      setAllItemsLength(response.data.allItemsLength);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAllItems();
  }, []);

  const fetchAllItemsMarked = async () => {
    try {
      const response = await axios.post(
        `${link}/api/get-all-items-marked-length`
      );
      setAllItemsMarkedLength(response.data.allItemsMarkedLength);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchAllItemsMarked();
  }, []);

  const refreshStats = () => {
    window.location.reload();
    dispatch({ type: "startscreen" });
  };

  return (
    <div className="userbox">
      {loggedIn && (
        <>
          <img
            src="poze/user-avatar.jpg"
            alt="user-avatar"
            className="userbox-avatar"
          />
          <div className="userbox-details">
            <div className="userbox-welcome">
              Hello, <span className="userbox-username">{firstName}</span>
            </div>
            <div className="userbox-stats">
              <img src="poze/stats.svg" alt="statssvg" className="btn-svg" />{" "}
              Marked / Total - {allItemsMarkedLength} / {allItemsLength}
            </div>
            <button className="userbox-btn" onClick={onLogout}>
              Logout
              <img src="poze/logout.svg" alt="logout" className="logout-logo" />
            </button>
          </div>

          <img
            src="poze/homepage.svg"
            alt="home"
            className="home-logo"
            onClick={refreshStats}
          />
          <div className="home-text">Home</div>
        </>
      )}
    </div>
  );
}
